// IMPORTS
@import (reference) "../settings.less";

.mainpage-greeting {
    display: flex;
    flex-direction: column;
    align-items: center;

    .headline {
        text-align: center;
        margin-bottom: 24px;
    }
}

.greeting-body {
    text-align: center;
    color: @sec-color;
    max-width: 950px;
}